import React, { useState, useEffect, useContext } from "react";


import { useLocation, useNavigate } from "react-router-dom";


import Header from "../../components/HomeComponents/Header";


import axios1 from "axios";


import { motion } from "framer-motion";


import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";


import { toast } from "react-toastify";


import { SessionContext } from "../../components/UserContext";


import axios from "../../utils/axios";

import { Buffer } from "buffer";


let UserData;


const Cart = () => {


  const { state, updateState } = useContext(SessionContext);


  const location = useLocation();


  const data = location.state;


  const navigate = useNavigate();


  const [paymentMethod, setPaymentMethod] = useState("");


  const [totalPrice, setTotalPrice] = useState(data.price);

  const [disableButton, setDisableButton] = useState(false);


  const { width } = useWindowDimensions();


  useEffect(() => {

try{
    UserData = JSON.parse(localStorage.getItem('userDataToken'));  

}catch(ee){
}

    let listenEP = setInterval(() => {


      if (PAYMENTRESULT) {


        PaymentResults(PAYMENTRESULT)


        PAYMENTRESULT = ''


      }


    }, 1500);



    return () => clearInterval(listenEP)


  }, []);



  // Function to handle payment method selection


  const handlePaymentMethodChange = (e) => {


    setPaymentMethod(e.target.value);


  };


  function PaymentResults(results) {

// alert(JSON.stringify(results))
    if (results.paymentStatus == 'S') {


      const url = "https://easypaisa.vouch365.mobi/api/post-transaction"


      const heads = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${state.token}`,
      };


// alert(UserData.userDetails.userInfo.userMsisdn);
// alert(alert(JSON.stringify(data)));
      const body = {
        "product_id": data.id,
        "store_id": 1,
        "store_name": "Vouch365",
        // "msisdn": UserData.userDetails.userInfo.userMsisdn,
        "msisdn": state.user.phone,
        "transaction_id": results.paymentTransactionID,
        "transaction_amount": totalPrice,
        "transaction_status": "PAID",
        "payment_method": "EasyPaisa",
        "order_id": results.paymentTransactionID,
        "provider": "EasyPaisa",
        "order_datetime": new Date(),
        "paid_datetime": new Date()
      };

      // alert(JSON.stringify(body))
      // alert(alert(JSON.stringify(body)));

      axios1
        .post(url, body, {
          headers: heads,
        })
        .then((response) => {
          //       alert('Success')
          const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${state.token}`,
          };


          axios
            .get("user", { headers: headers })
            .then((response) => {
              // alert('here');
              toast.success("You have successfully upgraded your membership!");
              var usrInfo = response.data.data;
              var constants = response.data.data.appConstants;
              updateState({ user: usrInfo, appConstants: constants });
              setTimeout(function () {
                setDisableButton(false)
                navigate("/HomeScreen");
              }, 50);
            })

            .catch((error) => {
              console.error(
                "There was an error fetching user information, please refresh the page.",
                error
              );
            });


        })


        .catch((error) => {


                  //  alert(JSON.stringify(error))


          console.error("There was an error!", error);


        })


      toast.success("Payment successfull!");


      toast.success(`Transaction ID : ${results.paymentTransactionID}`);


    } else {
      setDisableButton(false);
      toast.error("Something Went Wrong ! Please Try again Later .");


    }


    // alert('paymentTransactionID:' + result.paymentTransactionID);



    // alert('paymentStatus:' + result.paymentStatus);



    // alert('paymentCode:' + result.resultCode);  



  }


  // Function to handle confirming and paying


  const handleConfirmAndPay = async () => {


    // console.log(UserData)

try{
    setDisableButton(true)

    getUserAuthCodeResult(UserData?.tokens?.accessToken || state.user.access_token)
}catch(err){
// alert("missing access token")
}
    // console.log(paymentMethod)


    // if (paymentMethod == 'easypaisa') {


    //   var scopes = ["USER_MSISDN", "OPEN_ID", "USER_NAME"];


    //   mp.getUserAuthCode(scopes);


    // } else if (paymentMethod == '') {


    //   toast.error("Please Select Payment Method");


    // }


  };


  // "eb6fede2361ed929cb0f53f5f643ec06ffc312b0"


  function getUserAuthCodeResult(results) {


	const params = {

	accessToken: results,

	Currency: 'PKR',

	Amount: totalPrice * 100,

	paymentOrderTitle: 'Test'

	}

	let dataToSend = Buffer.from(JSON.stringify(params)).toString("base64")


    axios1


      .post("https://phpstack-771262-3912226.cloudwaysapps.com/CreatePaymentEP", {
      // .post("https://phpstack-771262-3979497.cloudwaysapps.com/CreatePaymentEP", {


	result : dataToSend,


      })


      .then((response) => {


        console.log(response.data.data.paymentTransactionID)


        mp.createPayment(`${response.data.data.paymentTransactionID}`)


        //easyPaisa(response.data.data.Signature, response.data.data.time)


      })


      .catch((error) => {


	alert("error while creating paymentid")

        console.log("There was an error!", error);


      });


  }



  return (


    <motion.div


      initial={{ y: 300, opacity: 0 }}


      animate={{ y: 0, opacity: 1 }}


      transition={{ duration: 1 }}


      className="page"


      style={{


        alignItems: "center",


        justifyContent: "center",


        flexDirection: "column",


        display: "flex",


      }}


    >


      <div


        style={{


          width: width < 800 ? "100%" : "80%",


          display: "flex",


          flexDirection: "column",


          alignSelf: "center",


        }}


      >


        <Header showback={true} heading={"Cart"} hideOpts={true} />



        <div


          style={{


            height: "100%",


            display: "flex",


            flexDirection: "column",


            width: width < 800 ? "100%" : "80%",


            alignSelf: "center",


          }}


        >


          <h2 style={{ marginLeft: "7px" }}>Shopping Cart</h2>



          <div style={{ marginLeft: "7px" }}>


            <h3>Product Details</h3>


            {/* Add your product details here */}


          </div>


          <div style={{ marginLeft: "7px" }}>


            <img


              src={data?.image}


              style={{


                width: "200px",


                height: "210px",


                objectFit: "cover",


                marginLeft: "10px",



                marginBottom: "10px",


              }}


            />


          </div>


          {/* Payment method */}


          <div style={{ marginLeft: "7px", marginRight: "7px" }}>


            <h3>{data.title}</h3>



            <div>{data.description}</div>



            {/* <div


              style={{


                backgroundColor: "white",


                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.9)", // Elevation


                border: "1px solid white",


                borderRadius: "10px",



                alignItems: "center",


                justifyContent: "flex-start",


                width: "80.74%",



                margin: "15px",


                padding: "20px",


                alignSelf: "center",


                justifySelf: "center",


              }}


            >


              <div>


                <h3


                  style={{


                    textAlign: "left",


                  }}


                >


                  Payment Method


                </h3>


              </div>


              <div


                style={{


                  display: "flex",


                  flexDirection: "column",


                }}


              >


                <label


              style={{


                marginBottom: "10px",


              }}


            >


              <input


                type="radio"


                value="card"


                checked={paymentMethod === "card"}


                onChange={handlePaymentMethodChange}


              />


              Pay by Card


            </label>


                <label


                  style={{


                    marginTop: "10px",


                    marginBottom: "10px",


                  }}


                >


                  <input


                    type="radio"


                    value="easypaisa"


                    checked={paymentMethod === "easypaisa"}


                    onChange={handlePaymentMethodChange}


                  />


                  Pay with EasyPaisa


                </label>


                <label


                  style={{


                    marginBottom: "10px",


                  }}


                >


                  <input


                    type="radio"


                    value="jazzcash"


                    checked={paymentMethod === "jazzcash"}


                    onChange={handlePaymentMethodChange}


                  />


                  Pay with Jazzcash


                </label>


              </div>


            </div> */}


          </div>


          {/* Total price */}


          <div style={{ marginLeft: "7px", marginBottom: "20px" }}>


            <h3>Total Price: {totalPrice} PKR</h3>


            {/* Add your logic to calculate and update the total price */}


          </div>



          {/* Confirm and Pay button */}


          <button


            onClick={handleConfirmAndPay}


            style={{


              alignItems: "center",


              justifyContent: "center",


              display: "flex",


              width: "70%",


              height: "60px",


              backgroundColor: "#66cf69",


              border: "none",


              outline: "none",


              marginLeft: "20px",


              alignSelf: "center",


              color: "white",


              fontWeight: 600,


              fontSize: "18px",


              borderRadius: 50,


            }}


            disabled={disableButton}

          >


            {disableButton ? 'Please Wait ...' : 'Confirm and Pay'}


          </button>


        </div>


      </div>


    </motion.div>


  );


};



export default Cart;
