import FoodCard from "../../components/HomeComponents/FoodCard";
import Header from "../../components/HomeComponents/Header";
import HeaderSearch from "../../components/HomeComponents/HeaderSearch";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import Filter from "../../images/filter.svg";
import Footer from "../../components/Footer";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from "../../utils/axios";
import { SessionContext } from "../../components/UserContext";
import debounce from "lodash/debounce";
import Filters from "../Filters";
import GoogleMap from "../../components/HomeComponents/GoogleMap";
import { motion } from "framer-motion"

function Category() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterView, showFilterView] = useState(false);
  const [offerType, setOfferType] = useState(location.state.offer_id);
  const { state } = useContext(SessionContext);
  // //console.log(location.state.offer_id);
  const [sortMethod, setSortMethod] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [leftPadding, setLeftPadding] = useState("20");
  const [visible,setVisible] = useState(10);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1500);
  }, []);
  const showMoreItems = ()=>{
    setVisible((prevValue)=>prevValue+10);
  }
  useEffect(() => {
    const debouncedSearch = debounce(() => {
      // //console.log(searchTerm);
      var cityId = 1;
      if (state.selectedCity.id != -1) {
        cityId = state.selectedCity.id;
      }
      var vendorList =
        `vendor?category_id=` +
        location.state.id +
        `&offer_id=` +
        offerType +
        `&city_id=` +
        cityId;
      if (searchTerm != "") {
        vendorList =
          `searchVendor?category_id=` +
          location.state.id +
          `&offer_id=` +
          offerType +
          `&city_id=` +
          cityId +
          `&searchKey=` +
          searchTerm;
      }
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      axios
        .get(vendorList, {
          headers: headers,
        })
        .then((response) => {
          var dt = response.data.data;
          let vendorCoordinate = dt.map((data) => {
            let vendorObj = {
              id: data.id,
              name: data.name,
              latitude: data.latitude,
              longitude: data.longitude,
            };
            return vendorObj;
          });
          setVendors(vendorCoordinate);
          if (sortMethod == 1) {
            //sort response.data.data by array key rating descending
            dt.sort(function (a, b) {
              return b.rating - a.rating || b.ratings_count - a.ratings_count;
            });
          } else if (sortMethod == 3) {
            dt = sortNearby(dt);
          }
          setData(dt);
          // //console.log(response, "abc");
        })
        .catch((error) => {
          setData([]);
          console.error("There was an error!", error);
        });
    }, 500);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, offerType, sortMethod]);

  function sortNearby(dt) {
    function sortObjectsByDistance(objects, center) {
      // Calculate the distance between two points on a sphere using Haversine formula
      function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
      }

      function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }

      // Calculate the distance between each object and the center point
      const objectsWithDistances = objects.map((obj) => {
        const distance = getDistanceFromLatLonInKm(
          center.latitude,
          center.longitude,
          obj.latitude,
          obj.longitude
        );
        return { ...obj, distance };
      });

      // Sort the objects by distance
      objectsWithDistances.sort((a, b) => a.distance - b.distance);

      return objectsWithDistances;
    }

    const center = { latitude: 24.9204, longitude: 67.0944 };

    const sortedObjects = sortObjectsByDistance(dt, center);
    //console.log(sortedObjects);
    return sortedObjects;
  }

  function searchVendors(e) {
    const term = e.target.value;
    if (term.length == 0) {
      setSearchTerm("");
      return;
    }
    if (term.length < 3) {
      return;
    }
    setSearchTerm(term);
  }
  function viewMap() {
    showMap ? setLeftPadding(20) : setLeftPadding(0);
    setShowMap(!showMap);
  }

  const filters = ["Filters", "Top-rated", "New", "Location"];

  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {filterView ? (
          <div
            style={{
              display: "flex",
              width: "90%",
              alignSelf: "center",
            }}
          >
            <Filters
              Close={() => showFilterView(false)}
              selectedOffer={offerType}
              HandleChange={(val) => setOfferType(val)}
              selSort={sortMethod}
              sortMethodChange={(val) => setSortMethod(val)}
            />
          </div>
        ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Header showback heading={location.state.name} phone />
                <HeaderSearch HandleSearch={searchVendors} viewMap={viewMap} />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  alignSelf: "center",
                }}
              >
                {showMap ? (
                  <GoogleMap vendors={vendors} />
                ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                        }}
                      >
                        {filters.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              margin: "0 2px",
                              border: "2px solid #000",
                              borderRadius: "5px",
                              display: "flex",
                              backgroundColor:
                                index === 0 || sortMethod == index
                                  ? "black"
                                  : "white",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              if (index === 0) showFilterView(true);
                              else if (index == 1) setSortMethod(1);
                              else if (index == 2) setSortMethod(2);
                              else if (index == 3) setSortMethod(3);
                            }}
                          >
                            {index === 0 ? (
                              <img
                                src={Filter}
                                alt=""
                                style={{ height: "10px", marginLeft: "10px" }}
                              />
                            ) : null}
                            <p
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                margin: "5px 10px",
                                color:
                                  index === 0 || sortMethod == index
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {item}
                            </p>
                          </div>
                        ))}
                      </div>
                      {data.length !== 0 &&
                        <motion.div
                          initial={{ y: 300, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 1 }}
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: 20,
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {data.slice(0,visible).map((item, index) => (
                            <>
                            <div key={index}>
                              <a
                                onClick={() =>
                                  navigate("/offers", {
                                    state: { id: item.id, offerType: offerType },
                                  })
                                }
                              >
                                <FoodCard
                                  item={item}
                                  imgWidth={width}
                                  imgHeight={"120px"}
                                  rating
                                  ScreenName={'Category'}
                                />
                              </a>
                            </div>
                            </>
                          ))}
                        </motion.div>
                      }
                       {showButton && (
                        <div className="text-center" style={{ marginTop: '50px', marginBottom: '100px' }}>
                          <button className="load-more-btn" onClick={showMoreItems}>
                            Load More
                          </button>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </>
          )}
      </div>
    </motion.div>
  );
}

export default Category;
